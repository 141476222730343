import React, { useState, useEffect, useContext } from "react";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, Button, CircularProgress } from "@mui/material";
import { LabelInput } from "../../../components/LabelInput";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import AuthContext from "../../../context/auth-context";

function ContactGroupEditionDialog(props) {
  const { open, setOpen, setSnackBarController, handleRefresh, contactGroup } = props;

  const company_key = useContext(AuthContext).user_data.company_key;

  const [loading, setLoading] = useState(false);

  const [snackBarStatus, setSnackBarStatus] = useState({
    opened: false,
    severity: "success",
    message: "",
  });

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackBarStatus({ ...snackBarStatus, opened: false });
  };

  const primaryColor = "#0b1f82";

  const [errors, setErrors] = useState({
    name: "",
  });

  const [touched, setTouched] = useState({
    name: false,
  });

  const errorMessages = {
    name: "Nome é obrigatório",
  };

  const handleBlur = (id, value) => {
    const error = !validateInput[id](value);
    setErrors({
      ...errors,
      [id]: error,
    });
    setValues({
      ...values,
      [id]: value,
    });
    setTouched((prevTouched) => ({
      ...prevTouched,
      [id]: true,
    }));
  };

  const [values, setValues] = useState({
    name: "",
  });

  useEffect(() => {
    if (contactGroup) {
      setValues({
        name: contactGroup.name || "",
      });
    }
  }, [contactGroup]);

  const resetForm = () => {
    setValues({
      name: "",
    });
    setErrors({
      name: "",
    });
  };

  const validateForm = () => {
    const allValues = {
      ...values,
    };

    const allTouched = {
      ...touched,
      name: true,
    };

    setTouched(allTouched);

    const areFieldsValid = Object.keys(allValues).every((key) => {
      return validateInput[key](values[key]);
    });

    return areFieldsValid;
  };

  const validateInput = {
    name: (value) => value !== "",
  };

  const handleInputChange = (field, value) => {
    const updatedValues = { ...values };
    updatedValues[field] = value;
    setValues(updatedValues);
  };

  const handleSubmit = async () => {
    const valid = validateForm();
    if (!valid) {
      return;
    }

    setLoading(true);

    const contactGroupData = {
      name: (values.name).trim(),
    };

    axios
      .patch(`/dash/sign/company/${company_key}/contact_group/${contactGroup.contact_group_key}`, contactGroupData)
      .then((response) => {
        setSnackBarController({
          opened: true,
          severity: "success",
          message: "Grupo de contatos atualizado com sucesso",
        });
        resetForm();
        setOpen(false);
        handleRefresh();
      })
      .catch((error) => {
        setSnackBarStatus({
          opened: true,
          severity: "error",
          message: "Ocorreu um erro ao atualizar o grupo de contatos",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return (
      <Dialog open={open} onClose={() => setOpen(false)}>
        <div
          style={{
            width: "400px",
            padding: "2rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "400px",
          }}
        >
          <CircularProgress />
        </div>
      </Dialog>
    );
  }

  return (
    <>
      <Dialog open={open}>
        <div style={{ width: "400px", padding: "2rem" }}>
          <IconButton
            aria-label="Close"
            onClick={() => {
              setOpen(false);
              resetForm();
            }}
            style={{ float: "right", width: "30px", height: "30px" }}
          >
            <CloseIcon />
          </IconButton>
          <h2 style={{ marginBottom: "1.5rem" }}>Edição do Grupo de Contatos</h2>
          <LabelInput
            labelValue="Nome"
            value={values.name}
            onChange={(value) => handleInputChange("name", value)}
            onBlur={(value) => handleBlur("name", value)}
            error={touched.name && values.name === ""}
            errorMessage={errorMessages.name}
          />
          <div style={{ alignItems: "center", textAlign: "center" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              style={{
                width: "100%",
                height: "32px",
                fontSize: "12px",
                margin: "1rem 0",
                backgroundColor: primaryColor,
                color: "white",
              }}
            >
              Atualizar
            </Button>
          </div>
        </div>
      </Dialog>
      <Snackbar
        open={snackBarStatus.opened}
        autoHideDuration={2000}
        onClose={handleCloseSnackBar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackBar}
          severity={snackBarStatus["severity"]}
        >
          {snackBarStatus.message}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default ContactGroupEditionDialog;
