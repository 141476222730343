import React from "react";
import TextField from "@material-ui/core/TextField";
import IconButton from "@mui/material/IconButton";
import * as S from "./styles";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

const SearchBar = ({ label, content, setContent, setRefresher, refresher, onSearch, variable }) => {
  const handleSearch = (searchContent) => {
    const normalizedContent = (searchContent || "").trim();
    const queryParameter = `?${variable}=${encodeURIComponent(normalizedContent)}`;
    onSearch(queryParameter);
    setRefresher(!refresher);
  };

  return (
    <S.Container>
      <TextField
        id="outlined-basic"
        label={label}
        style={{ width: "320px" }}
        variant="outlined"
        value={content || ""}
        onChange={(e) => setContent(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSearch(content);
          }
        }}
        fullWidth
        InputProps={{
          style: {
            fontSize: "14px"
          },
        }}
        InputLabelProps={{
          style: {
            fontSize: "14px"
          }
        }}
      />
      <IconButton
        aria-label="pesquisar"
        onClick={() => handleSearch(content)} 
        style={{ width: 36, height: 36 }}
      >
        <SearchIcon />
      </IconButton>
      <IconButton
        aria-label="limpar"
        onClick={() => {
          setContent("");
          setRefresher(!refresher);
          handleSearch(""); 
        }}
        style={{ width: 36, height: 36 }}
      >
        <CloseIcon />
      </IconButton>
    </S.Container>
  );
};

export default SearchBar;
