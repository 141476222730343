import React, { useState, useEffect, useContext } from "react";
import { IconButton } from "@mui/material";
import {
  formatPhone,
  isPhone,
  isEmail,
  formatCPF,
  formatDate,
  isValidDate,
  isValidCPF,
  formatDateToISOZulu,
  formatPhoneToFront,
  formatBirthdateToFront
} from "../../../utils/utils";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, Button, CircularProgress } from "@mui/material";
import { LabelInput } from "../../../components/LabelInput";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import AuthContext from "../../../context/auth-context";
import { Radio } from "@mui/material";
import { RadioGroup } from "@mui/material";
import { FormControlLabel } from "@mui/material";

function ContactEditionDialog(props) {
  const { open, setOpen, setSnackBarController, handleRefresh, contactGroupKey, contact } =
    props;

  const company_key = useContext(AuthContext).user_data.company_key;

  const [loading, setLoading] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [snackBarStatus, setSnackBarStatus] = useState({
    opened: false,
    severity: "success",
    message: "",
  });

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackBarStatus({ ...snackBarStatus, opened: false });
  };

  const primaryColor = "#0b1f82";

  const [errors, setErrors] = useState({
    contact_name: "",
    name: "",
    birthdate: "",
    document_number: "",
    email: "",
    phone: "",
    document_submission_method: "",
    authentication_submission_method: "",
  });

  const [touched, setTouched] = useState({
    contact_name: false,
    name: false,
    birthdate: false,
    document_number: false,
    email: false,
    phone: false,
    document_submission_method: false,
    authentication_submission_method: false,
  });

  const errorMessages = {
    contact_name: "Nome do contato é obrigatório",
    phone: "Telefone inválido",
    email: "Email inválido",
    name: "Nome completo é obrigatório",
    birthdate: "Data de nascimento inválida",
    document_number: "CPF inválido",
    document_submission_method: "Método de envio dos documentos é obrigatório",
    authentication_submission_method:
      "Método de envio do código de autenticação é obrigatório",
  };

  const handleBlur = (id, value) => {
    const error = !validateInput[id](value);
    setErrors({
      ...errors,
      [id]: error,
    });
    setValues({
      ...values,
      [id]: value,
    });
    setTouched((prevTouched) => ({
      ...prevTouched,
      [id]: true,
    }));
  };

  const [values, setValues] = useState({
    contact_name: "",
    name: "",
    birthdate: "",
    document_number: "",
    email: "",
    phone: "",
    document_submission_method: "",
    authentication_submission_method: "",
  });

  const resetForm = () => {
    setValues({
      contact_name: "",
      name: "",
      birthdate: "",
      document_number: "",
      email: "",
      phone: "",
      document_submission_method: "",
      authentication_submission_method: "",
    });
    setErrors({
      contact_name: "",
      name: "",
      birthdate: "",
      document_number: "",
      email: "",
      phone: "",
      document_submission_method: "",
      authentication_submission_method: "",
    });
  };

  useEffect(() => {
    
    if (contact) {
      setValues({
        contact_name: contact.contact_name,
        name: contact.contact_data.name,
        birthdate: formatBirthdateToFront(contact.contact_data.birthdate),
        document_number: contact.contact_data.document_number,
        email: contact.contact_data.email || "",
        phone: formatPhoneToFront(contact.contact_data.phone),
        document_submission_method: contact.contact_data.document_submission_method,
        authentication_submission_method: contact.contact_data.authentication_submission_method,
      });
    }
  }, [contact]);

  const validateForm = () => {
    const allValues = {
      ...values,
    };

    const allTouched = {
      ...touched,
      contact_name: true,
      name: true,
      birthdate: true,
      document_number: true,
      email: true,
      phone: true,
      document_submission_method: true,
      authentication_submission_method: true,
    };

    setTouched(allTouched);

    const areFieldsValid = Object.keys(allValues).every((key) => {
      if ((values.authentication_submission_method === "email" || values.document_submission_method === "email") && values.email === "") {
        return false
      }

      if ((values.authentication_submission_method === "whatsapp" || values.document_submission_method === "whatsapp") && values.phone === "") {
          return false
      }

      if ((values.authentication_submission_method === "sms" || values.document_submission_method === "sms") && values.phone === "") {
          return false
      }

      return validateInput[key](values[key]);
    });

    return areFieldsValid;
  };

  const validateInput = {
    phone: (value) => {
      return value === "" || isPhone(value);
    },
    email: (value) => { 
      return value === "" || isEmail(value)
    },
    contact_name: (value) => value !== "",
    name: (value) => value !== "",
    birthdate: (value) => {
      return value === "" || isValidDate(value);
    },
    document_number: (value) => value !== "",
    document_submission_method: (value) => value !== "",
    authentication_submission_method: (value) => value !== "",
  };

  const handleInputChange = (field, value) => {
    const updatedValues = { ...values };
    updatedValues[field] = value;
    setValues(updatedValues);
  };

  const handleSubmit = async () => {
    const valid = validateForm();
    if (!valid) {
      return;
    }

    setIsSubmitting(true)
    setLoading(true);

    const contactData = {
      name: values.name,
      document_number: values.document_number,
      document_submission_method: values.document_submission_method,
      authentication_submission_method: values.authentication_submission_method,
    };

    if (values.email !== "") {
      contactData.email = (values.email).trim()
    }

    if (values.phone !== "") {
      const digitPhone = values.phone.replace(/\D/g, '')
      contactData.phone = {
          international_dial_code: '55',
          area_code: digitPhone.substring(0, 2),
          number: digitPhone.substring(2, 11)
      }
    };

    if (values.birthdate !== ""){
      contactData.birthdate = formatDateToISOZulu(values.birthdate, 'birthdate')
    };

    const updateContactPayload = {
      contact_name: (values.contact_name).trim(),
      contact_data: contactData,
    };

    axios
      .patch(
        `/dash/sign/company/${company_key}/contact_group/${contactGroupKey}/contact/${contact.contact_key}`,
        updateContactPayload
      )
      .then((response) => {
        setSnackBarController({
          opened: true,
          severity: "success",
          message: "Contato atualizado com sucesso",
        });
        resetForm();
        setOpen(false);
        handleRefresh();
      })
      .catch((error) => {
        setSnackBarStatus({
          opened: true,
          severity: "error",
          message: "Ocorreu um erro ao atualizar o contato",
        });
      })
      .finally(() => {
        setLoading(false);
        setIsSubmitting(false);
      });
  };

  if (loading) {
    return (
      <Dialog open={open} onClose={() => setOpen(false)}>
        <div
          style={{
            width: "400px",
            padding: "2rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "400px",
          }}
        >
          <CircularProgress />
        </div>
      </Dialog>
    );
  }

  return (
    <>
      <Dialog open={open}>
        <div style={{ width: "400px", padding: "2rem" }}>
          <IconButton
            aria-label="Close"
            onClick={() => {
              setOpen(false);
              resetForm();
            }}
            style={{ float: "right", width: "30px", height: "30px" }}
          >
            <CloseIcon />
          </IconButton>
          <h2 style={{ marginBottom: "1.5rem" }}>Edição de Contato</h2>
          <LabelInput
            labelValue="Nome para o contato"
            value={values.contact_name}
            onChange={(value) => handleInputChange("contact_name", value)}
            onBlur={(value) => handleBlur("contact_name", value)}
            error={touched.contact_name && values.contact_name === ""}
            errorMessage={errorMessages.contact_name}
          />
          <LabelInput
            labelValue="Nome completo"
            value={values.name}
            onChange={(value) => handleInputChange("name", value)}
            onBlur={(value) => handleBlur("name", value)}
            error={touched.name && values.name === ""}
            errorMessage={errorMessages.name}
          />
          <LabelInput
            labelValue="Data de Nascimento (Opcional)"
            value={values.birthdate}
            placeholder="DD/MM/AAAA"
            onChange={(value) => handleInputChange("birthdate", value)}
            formatValue={(value) => formatDate(value)}
            onBlur={(value) => handleBlur("birthdate", value)}
            error={
              touched.birthdate &&
              !isValidDate(values.birthdate) &&
              values.birthdate !== ""
            }
            errorMessage={errorMessages.birthdate}
          />
          <LabelInput
            labelValue="CPF"
            value={values.document_number}
            placeholder="000.000.000-00"
            onChange={(value) => handleInputChange("document_number", value)}
            onBlur={(value) => handleBlur("document_number", value)}
            formatValue={(value) => formatCPF(value)}
            error={
              (values.document_number && values.document_number === "") ||
              (touched.document_number && !isValidCPF(values.document_number))
            }
            errorMessage={errorMessages.document_number}
          />
          <LabelInput
            labelValue="E-mail (Opcional)"
            value={values.email}
            onChange={(value) => handleInputChange("email", value)}
            onBlur={(value) => handleBlur("email", value)}
            error={
              touched.email &&
              (values.document_submission_method === "email" ||
                values.authentication_submission_method === "email") &&
              !isEmail(values.email)
            }
            errorMessage={errorMessages.email}
          />
          <LabelInput
            labelValue="Telefone (Opcional)"
            value={values.phone}
            placeholder="(00) 00000-0000"
            onChange={(value) => handleInputChange("phone", value)}
            onBlur={(value) => handleBlur("phone", value)}
            formatValue={(value) => formatPhone(value)}
            error={
              (touched.phone && !isPhone(values.phone)) ||
              ((values.document_submission_method === "sms" ||
                values.authentication_submission_method === "sms") &&
                values.phone === "") ||
              ((values.document_submission_method === "whatsapp" ||
                values.authentication_submission_method === "whatsapp") &&
                values.phone === "")
            }
            errorMessage={errorMessages.phone}
          />
          <div>
            <h3 style={{ marginBottom: "1rem" }}>
              Método de Envio dos Documentos
            </h3>
            <RadioGroup
              row
              name="document_submission_method"
              value={values.document_submission_method}
              onChange={(event) =>
                handleInputChange(
                  "document_submission_method",
                  event.target.value
                )
              }
            >
              <FormControlLabel
                value="email"
                control={
                  <Radio
                    style={{ color: primaryColor, borderColor: primaryColor }}
                  />
                }
                label="E-mail"
              />
              <FormControlLabel
                value="sms"
                control={
                  <Radio
                    style={{ color: primaryColor, borderColor: primaryColor }}
                  />
                }
                label="SMS"
              />
              <FormControlLabel
                value="whatsapp"
                control={
                  <Radio
                    style={{ color: primaryColor, borderColor: primaryColor }}
                  />
                }
                label="WhatsApp"
              />
            </RadioGroup>
            {isSubmitting && values.document_submission_method === "" && (
              <p style={{ color: "#B22300" }}>
                {errorMessages.document_submission_method}
              </p>
            )}
          </div>
          <div>
            <h3 style={{ marginBottom: "1rem" }}>
              Método de Envio da Autenticação
            </h3>
            <RadioGroup
              row
              name="authentication_submission_method"
              value={values.authentication_submission_method}
              onChange={(event) =>
                handleInputChange(
                  "authentication_submission_method",
                  event.target.value
                )
              }
            >
              <FormControlLabel
                value="email"
                control={
                  <Radio
                    style={{ color: primaryColor, borderColor: primaryColor }}
                  />
                }
                label="E-mail"
              />
              <FormControlLabel
                value="sms"
                control={
                  <Radio
                    style={{ color: primaryColor, borderColor: primaryColor }}
                  />
                }
                label="SMS"
              />
              <FormControlLabel
                value="whatsapp"
                control={
                  <Radio
                    style={{ color: primaryColor, borderColor: primaryColor }}
                  />
                }
                label="WhatsApp"
              />
              {isSubmitting &&
                values.authentication_submission_method === "" && (
                  <p style={{ color: "#B22300" }}>
                    {errorMessages.authentication_submission_method}
                  </p>
                )}
            </RadioGroup>
          </div>
          <div style={{ alignItems: "center", textAlign: "center" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              style={{
                width: "100%",
                height: "32px",
                fontSize: "12px",
                margin: "1rem 0",
                backgroundColor: primaryColor,
                color: "white",
              }}
            >
              Atualizar
            </Button>
          </div>
        </div>
      </Dialog>
      <Snackbar
        open={snackBarStatus.opened}
        autoHideDuration={2000}
        onClose={handleCloseSnackBar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackBar}
          severity={snackBarStatus["severity"]}
          style={{fontSize: "12px"}}
        >
          {snackBarStatus.message}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

export default ContactEditionDialog;
